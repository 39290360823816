/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import upperFirst from 'lodash/upperFirst'

// Helpers
import { formatNumber } from '../helpers/helpers';

// Layout imports
import PDFLayout from '../layouts/pdf-layout';
import PDFSummary from '../layouts/pdf-layout/summary';
import Results from '../layouts/salary-sacrifice/results';

const SalarySacrificeReport = forwardRef(({
    headerImage,
    heading,
    description,
    definitions,
    salaryBefore,
    salarySimple,
    salarySmart,
    personalAllowanceBefore,
    personalAllowanceSimple,
    personalAllowanceSmart,
    incomeTaxBefore,
    incomeTaxSimple,
    incomeTaxSmart,
    employeeNICBefore,
    employeeNICSimple,
    employeeNICSmart,
    employerNICBefore,
    employerNICSimple,
    employerNICSmart,
    takeHomePayBefore,
    takeHomePaySimple,
    takeHomePaySmart,
    totalPensionContributionBefore,
    totalPensionContributionSimple,
    totalPensionContributionSmart,
    differenceUsingSmartVsBefore,
    taxableIncomeBefore,
    taxableIncomeSimple,
    taxableIncomeSmart,
    employerNISavings,
    employeePensionContributionSmart,
    employerPensionContributionBefore,
}, ref) => {
    // React Redux hooks
    const figures = useSelector(state => state.yearlyFigures);
    const query = useSelector(state => state.salarySacrifice);

    // Check if value is < 0 and rationalise
    const checkNegative = (value) => value < 0 ? 0 : value;

    const taxYear = `${figures.selectedYear}/${parseInt(figures.selectedYear.slice(-2), 10) + 1}`;

	return (
	    <PDFLayout
            headerImage={headerImage}
            heading={heading}
            description={`${description} - this document includes a full breakdown of your personal calculation results and other relevant information`}
            definitions={definitions}
            ref={ref}
        >
            <h3>1. Your figures &amp; selections</h3>

            <p>The following shows a summary of all figures you entered into our {heading} and any other selections you've made.</p>
            
            <PDFSummary>
                <PDFSummary.Item>
                    <strong>Gross income:</strong> {query.grossIncome ? formatNumber(query.grossIncome) : '-'}
                </PDFSummary.Item>

                <PDFSummary.Item>
                    <strong>Paid per:</strong> {query.paidPer ? upperFirst(query.paidPer) : '-'}
                </PDFSummary.Item>

                <PDFSummary.Item>
                    <strong>Tax year:</strong> {taxYear}
                </PDFSummary.Item>

                <PDFSummary.Item>
                    <strong>Employee pension contribution:</strong> {query.employeeContribution ? query.employeeContributionInPounds ? formatNumber(query.employeeContribution) : `${query.employeeContribution}%` : '-'}
                </PDFSummary.Item>

                <PDFSummary.Item>
                    <strong>Employer pension contribution:</strong> {query.employerContribution ? query.employerContributionInPounds ? formatNumber(query.employerContribution) : `${query.employerContribution}%` : '-'}
                </PDFSummary.Item>

                <PDFSummary.Item>
                    <strong>Employer NI saving given up:</strong> {query.percentageSacrificed ? `${query.percentageSacrificed}%` : '-'}
                </PDFSummary.Item>
            </PDFSummary>

            <hr />

            <h3>2. Your results</h3>

            <PDFSummary.Item>
                <strong>You could recieve a total pension contribution of</strong> {formatNumber(totalPensionContributionSmart)} <strong>per year with a SMART Salary Sacrifice pension scheme. This is </strong> {formatNumber(differenceUsingSmartVsBefore)} <strong>more than a standard non-Salary Sacrifice pension scheme.</strong>
            </PDFSummary.Item>

            <PDFSummary.Item>
                <strong>Your total SMART pension contribution is made up from:</strong>
                <br/><br/>
                <strong>Employee pension contribution: </strong>{formatNumber(employeePensionContributionSmart)} <strong>+</strong>
                <br/>
                <strong>Employer pension contribution: </strong>{formatNumber(employerPensionContributionBefore)} <strong>+</strong>
                <br/>
                <strong>Additional employer NI savings: </strong>{formatNumber(employerNISavings)}
                <span style={{height: '1px', width: '50%', margin: '10px 0', display: 'block', background: '#f7f7f7'}} />
                <strong>Total pension contribution: </strong>{formatNumber(totalPensionContributionSmart)}
            </PDFSummary.Item>

            <br/>
            <br/>
            <br/>
            <br/>
            <br/>

            <h4>Full breakdown:</h4>

            <Results
                definitions={definitions}
                salaryBefore={salaryBefore}
                salarySimple={salarySimple}
                salarySmart={salarySmart}
                personalAllowanceBefore={personalAllowanceBefore}
                personalAllowanceSimple={personalAllowanceSimple}
                personalAllowanceSmart={personalAllowanceSmart}
                incomeTaxBefore={incomeTaxBefore}
                incomeTaxSimple={incomeTaxSimple}
                incomeTaxSmart={incomeTaxSmart}
                employeeNICBefore={employeeNICBefore}
                employeeNICSimple={employeeNICSimple}
                employeeNICSmart={employeeNICSmart}
                employerNICBefore={employerNICBefore}
                employerNICSimple={employerNICSimple}
                employerNICSmart={employerNICSmart}
                takeHomePayBefore={takeHomePayBefore}
                takeHomePaySimple={takeHomePaySimple}
                takeHomePaySmart={takeHomePaySmart}
                totalPensionContributionBefore={totalPensionContributionBefore}
                totalPensionContributionSimple={totalPensionContributionSimple}
                totalPensionContributionSmart={totalPensionContributionSmart}
                differenceUsingSmartVsBefore={differenceUsingSmartVsBefore}
                taxableIncomeBefore={checkNegative(taxableIncomeBefore)}
                taxableIncomeSimple={checkNegative(taxableIncomeSimple)}
                taxableIncomeSmart={checkNegative(taxableIncomeSmart)}
            />

            <hr />

            <h3>3. About Salary Sacrifice</h3>

            <p>A Salary Sacrifice arrangement (sometimes called Salary Exchange) is an agreement between an employee and their employer to reduce the employee’s entitlement to cash pay, usually in return for a non-cash benefit.</p>

            <p>By sacrificing a portion of your pay you are effectively earning a lower salary and therefore both you and your employer will pay less National Insurance (NI).</p>
            
            <p>The NI that you save, along with any NI saving that your employer chooses to pass on, is paid into your pension pot as an employer contribution. These NI contributions are made in addition to your agreed workplace pension contributions.</p>

            <p>As an employer, you can set up a new Salary Sacrifice arrangement by updating the terms of your employee’s employment contract. However, your employee must agree to this change.</p>

            <h3>3.1. How it works</h3>

            <p>There are two types of Salary Sacrifice pension scheme: ‘Simple Salary Sacrifice’ and ‘SMART Salary Sacrifice’ (Save More and Reduce Tax). Both resulting in the employee and employer paying less National Insurance (NI).</p>

            <h4>3.1.1. Simple Salary Sacrifice</h4>

            <p>An agreed amount of your salary is given up before calculating your tax and take home pay. You are effectively earning a lower salary and therefore both you and your employer will pay less National Insurance (NI). The amount that you, the employee, save in National Insurance (NI) will be reflected as an increase in your take home pay.</p>

            <h4>3.1.2. SMART Salary Sacrifice</h4>
            
            <p>An agreed amount of your salary is given up before calculating your tax and take home pay. You are effectively earning a lower salary and therefore both you and your employer will pay less National Insurance (NI). The amount that you, the employee, save in National Insurance (NI) will be paid into your pension pot. Your take home pay would be the same as if you didn’t use Salary Sacrifice.</p>

            <p>Your employer also has a choice to pass on their savings in National Insurance (NI) to you by adding their savings to your pension pot.</p>
            
            <h3>3.2. An example</h3>

            <p>The following example compares pension contributions for a salary of £25,000, an employee pension contribution of 5% and an employer pension contribution of 3%. Figures are present for before and after SMART Salary Sacrifice. Figures shown are based on the 2021/2022 tax year and may be subject to change.</p>

            <table className='table'>
                <thead>
                    <tr>
                        <th></th>
                        <th>Standard Pension Contribution</th>
                        <th>With SMART Salary Sacrifice</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><strong>Annual salary</strong></td>
                        <td>£25,000.00</td>
                        <td>£23,529.41</td>
                    </tr>
                    <tr>
                        <td><strong>Income Tax</strong></td>
                        <td>£2,236.00</td>
                        <td>£2,191.88</td>
                    </tr>
                    <tr>
                        <td><strong>Employee National Insurance (NI)</strong></td>
                        <td>£1,851.84</td>
                        <td>£1,675.37</td>
                    </tr>
                    <tr>
                        <td><strong>Employer National Insurance (NI)</strong></td>
                        <td>£2,230.08</td>
                        <td>£2,027.14</td>
                    </tr>
                    <tr>
                        <td><strong>Take home pay</strong></td>
                        <td>£19,662.16</td>
                        <td>£19,662.16</td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td><strong>Total pension contribution</strong></td>
                        <td>£2,000.00</td>
                        <td>£2,220.59</td>
                    </tr>
                </tfoot>
            </table>

            <p>As you can see from the table above, take home pay is unaffected but due to the savings in National Insurance, your pension contributions are higher.</p>

            <h3>3.3. Effects of Salary Sacrifice on payments and benefits</h3>

            <p>Sacrificing a portion of your income can have an impact on your eligibility or entitlement to certain other payments or benefits. Some of these are listed below.</p>

            <h4>3.3.1. Earnings related payments</h4>

            <p>Employers usually decide how payments such as: workplace pension contributions, overtime rates and pay rises are calculated. These could be calculated with either your salary before or after Salary Sacrifice has been applied. It is worth checking with your employer how these payments may be affected.</p>

            <h4>3.3.2. Earnings related benefits</h4>

            <p>Salary sacrifice can affect your entitlement to benefits such as: Maternity Allowance and Additional State Pension. The amount you receive may be less than the full standard rate, or you may even lose your entitlement altogether.</p>

            <h4>3.3.3. Contribution based benefits</h4>

            <p>Salary Sacrifice reduces your effective salary and may reduce your National Insurance (NI) contributions. This can affect your entitlement to Incapacity Benefit and State Pension.</p>

            <h4>3.3.4. Statutory payments</h4>

            <p>Salary Sacrifice can affect the amount of statutory pay an employee receives, such as Statutory Sick pay, and may even cause some employees to lose their entitlement altogether.</p>

            <p>If a Salary Sacrifice arrangement reduces an employee’s average weekly earnings below the lower earnings limit then an employer does not have to make any statutory payments to them.</p>

            <h3>3.4. Other considerations</h3>
            
            <ul>
                <li>A Salary Sacrifice arrangement must not reduce an employee’s cash earnings below the <a href="https://www.gov.uk/national-minimum-wage-rates">National Minimum Wage</a>. Employers must ensure that they cap Salary Sacrifice deductions to ensure that employees are paid in line with National Minimum Wage requirements.</li>
                <li>Some mortgage and money lenders may reduce their maximum borrow amount as your effective salary may be lower under Salary Sacrifice arrangements.</li>
                <li>'Death in service' benefits may be reduced if your salary is lower after Salary Sacrifice.</li>
                <li>Any amount of money that is Salary Sacrificed will still count towards your total income for determining your annual Personal Allowance.</li>
            </ul>
            
            <h3>3.5. Other benefits</h3>

            <p>There may be some other benefits to participating in a Salary Sacrifice scheme:</p>

            <ul>
                <li>You may receive an increase in child tax credits.</li>
                <li>You may be entitled to higher Child Benefit payments as your effective salary is reduced.</li>
                <li>Higher-rate taxpayers benefit from immediate higher-rate tax relief on the pension contribution instead of claiming it back from HMRC.</li>
                <li>Earners in excess of £100,000 may benefit from regaining a part or all of their Personal Allowance (dependent on the amount of salary sacrificed and total salary).</li>
            </ul>

            <p><em>moneyBeans cannot advise you on whether a Salary Sacrifice pension scheme is the right choice for you. If you’re still undecided; we recommend speaking to your employer and an independent financial adviser.</em></p>

            <hr />
        </PDFLayout>
	);
});

SalarySacrificeReport.propTypes = {
    headerImage: PropTypes.string.isRequired,
    heading: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    definitions: PropTypes.array.isRequired,
    salaryBefore: PropTypes.number.isRequired,
    salarySimple: PropTypes.number.isRequired,
    salarySmart: PropTypes.number.isRequired,
    personalAllowanceBefore: PropTypes.number.isRequired,
    personalAllowanceSimple: PropTypes.number.isRequired,
    personalAllowanceSmart: PropTypes.number.isRequired,
    incomeTaxBefore: PropTypes.number.isRequired,
    incomeTaxSimple: PropTypes.number.isRequired,
    incomeTaxSmart: PropTypes.number.isRequired,
    employeeNICBefore: PropTypes.number.isRequired,
    employeeNICSimple: PropTypes.number.isRequired,
    employeeNICSmart: PropTypes.number.isRequired,
    employerNICBefore: PropTypes.number.isRequired,
    employerNICSimple: PropTypes.number.isRequired,
    employerNICSmart: PropTypes.number.isRequired,
    takeHomePayBefore: PropTypes.number.isRequired,
    takeHomePaySimple: PropTypes.number.isRequired,
    takeHomePaySmart: PropTypes.number.isRequired,
    totalPensionContributionBefore: PropTypes.number.isRequired,
    totalPensionContributionSimple: PropTypes.number.isRequired,
    totalPensionContributionSmart: PropTypes.number.isRequired,
    differenceUsingSmartVsBefore: PropTypes.number.isRequired,
    taxableIncomeBefore: PropTypes.number.isRequired,
    taxableIncomeSimple: PropTypes.number.isRequired,
    taxableIncomeSmart: PropTypes.number.isRequired,
    employerNISavings: PropTypes.number.isRequired,
    employeePensionContributionSmart: PropTypes.number.isRequired,
    employerPensionContributionBefore: PropTypes.number.isRequired,
};

export default SalarySacrificeReport;