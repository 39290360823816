import React from 'react';
import { withPrefix, graphql } from 'gatsby';

// Layouts
import Default from '../../layouts/default';
import Seo from '../../layouts/seo';
import SalarySacrificeCalculator from '../../layouts/salary-sacrifice';

// Components
import PageHeader from '../../components/page-header';
import Content, { HTMLContent } from "../../components/content-renderer";

const SalarySacrifice = (props) => {
    const PageContent = HTMLContent || Content;

	const pageInfo = props.data.toolInfo;
    const definitions = props.data.definitions.nodes;
    const headerImage = pageInfo.frontmatter.image.relativePath;

    return (
        <Default>
            <Seo
                title={pageInfo.frontmatter.title}
                description={pageInfo.frontmatter.description}
            />

            <PageHeader
                heading={pageInfo.frontmatter.title}
                imageSource={`${withPrefix("/")}img/${headerImage}`}
                modalContent={(
                    <PageContent content={pageInfo.html} /> 
                )}
                description={pageInfo.frontmatter.description}
                ctaLink='/information/salary-sacrifice'
                breadcrumbs={[
                    {name: 'Home', url: '/'},
                    {name: 'Personal finance', url: '/personal-finance'},
                ]}
            />

            <SalarySacrificeCalculator
                definitions={definitions}
                pageInfo={pageInfo}
            />
        </Default>
    );
};

export default SalarySacrifice;

export const SalarySacrificeQuery = graphql`
    query SalarySacrificeQuery($id: String!) {
        toolInfo: markdownRemark(id: { eq: $id }) {
            id
            html
            frontmatter {
                description
                image {
                    relativePath
                }
                title
            }
        }
        definitions: allMarkdownRemark(
            filter: {fileAbsolutePath: {
                regex: "/src/definitions/gross-income|/src/definitions/employee-pension-contribution|/src/definitions/employer-pension-contribution|/src/definitions/employer-ni-saving|/src/definitions/adjusted-salary|/src/definitions/personal-allowance|/src/definitions/taxable-income|/src/definitions/income-tax|/src/definitions/employee-ni-contribution|/src/definitions/employer-ni-contribution|/src/definitions/take-home-pay|/src/definitions/total-pension-contribution|/src/definitions/additional-pension-contribution-with-smart|/src/definitions/automatic-enrolment-pensions|/src/definitions/simple-vs-smart-salary-sacrifice/"
            }},
            sort: {order: ASC, fields: frontmatter___title}
        ) {
            nodes {
                html
                frontmatter {
                    title
                }
            }
        }
    }
`;