/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// Components
import Table from '../../components/table';
import InfoModal from '../../components/info-modal';
import Content, { HTMLContent } from "../../components/content-renderer";

// Helpers
import { filterResults } from '../../helpers/helpers';

const Results = ({
    definitions,
    salaryBefore,
    salarySimple,
    salarySmart,
    personalAllowanceBefore,
    personalAllowanceSimple,
    personalAllowanceSmart,
    incomeTaxBefore,
    incomeTaxSimple,
    incomeTaxSmart,
    employeeNICBefore,
    employeeNICSimple,
    employeeNICSmart,
    employerNICBefore,
    employerNICSimple,
    employerNICSmart,
    takeHomePayBefore,
    takeHomePaySimple,
    takeHomePaySmart,
    totalPensionContributionBefore,
    totalPensionContributionSimple,
    totalPensionContributionSmart,
    differenceUsingSmartVsBefore,
    taxableIncomeBefore,
    taxableIncomeSimple,
    taxableIncomeSmart,
}) => {
    const PageContent = HTMLContent || Content;

    // React Redux hooks
    const query = useSelector(state => state.salarySacrifice);

    const filterBy = (value) => filterResults(value, query.filterResults, 5, 7.5);

    return (
        <Table>
            <Table.Header>
                <th></th>
                <th>
                    <InfoModal
                        triggerText='Without Salary Sacrifice'
                        heading={definitions[2].frontmatter.title}
                        variant='white'
                    >
                        <PageContent content={definitions[2].html} />
                    </InfoModal>
                </th>
                <th>
                    <InfoModal
                        triggerText='With Simple Salary Sacrifice'
                        heading={definitions[11].frontmatter.title}
                        variant='white'
                    >
                        <PageContent content={definitions[11].html} />
                    </InfoModal>
                </th>
                <th>
                    <InfoModal
                        triggerText='With SMART Salary Sacrifice'
                        heading={definitions[11].frontmatter.title}
                        variant='white'
                    >
                        <PageContent content={definitions[11].html} />
                    </InfoModal>
                </th>
            </Table.Header>
            <tbody>
                <tr>
                    <td>
                        <InfoModal
                            triggerText='Adjusted Salary'
                            heading={definitions[1].frontmatter.title}
                        >
                           <PageContent content={definitions[1].html} />
                        </InfoModal>
                    </td>
                    <td>{filterBy(salaryBefore)}</td>
                    <td>{filterBy(salarySimple)}</td>
                    <td>{filterBy(salarySmart)}</td>
                </tr>
                <tr>
                    <td>
                        <InfoModal
                            triggerText='Personal Allowance'
                            heading={definitions[10].frontmatter.title}
                        >
                           <PageContent content={definitions[10].html} />
                        </InfoModal>
                    </td>
                    <td>{filterBy(personalAllowanceBefore)}</td>
                    <td>{filterBy(personalAllowanceSimple)}</td>
                    <td>{filterBy(personalAllowanceSmart)}</td>
                </tr>
                <tr>
                    <td>
                        <InfoModal
                            triggerText='Taxable Income'
                            heading={definitions[13].frontmatter.title}
                        >
                           <PageContent content={definitions[13].html} />
                        </InfoModal>
                    </td>
                    <td>{filterBy(taxableIncomeBefore)}</td>
                    <td>{filterBy(taxableIncomeSimple)}</td>
                    <td>{filterBy(taxableIncomeSmart)}</td>
                </tr>
                <tr>
                    <td>
                        <InfoModal
                            triggerText='Income Tax'
                            heading={definitions[9].frontmatter.title}
                        >
                           <PageContent content={definitions[9].html} />
                        </InfoModal>
                    </td>
                    <td>{filterBy(incomeTaxBefore)}</td>
                    <td>{filterBy(incomeTaxSimple)}</td>
                    <td>{filterBy(incomeTaxSmart)}</td>
                </tr>
                <tr>
                    <td>
                        <InfoModal
                            triggerText='Employee NI Contribution'
                            heading={definitions[3].frontmatter.title}
                        >
                           <PageContent content={definitions[3].html} />
                        </InfoModal>
                    </td>
                    <td>{filterBy(employeeNICBefore)}</td>
                    <td>{filterBy(employeeNICSimple)}</td>
                    <td>{filterBy(employeeNICSmart)}</td>
                </tr>
                <tr>
                    <td>
                        <InfoModal
                            triggerText='Employer NI Contribution'
                            heading={definitions[5].frontmatter.title}
                        >
                           <PageContent content={definitions[5].html} />
                        </InfoModal>
                    </td>
                    <td>{filterBy(employerNICBefore)}</td>
                    <td>{filterBy(employerNICSimple)}</td>
                    <td>{filterBy(employerNICSmart)}</td>
                </tr>
                <tr>
                    <td>
                        <InfoModal
                            triggerText='Take Home Pay'
                            heading={definitions[12].frontmatter.title}
                        >
                           <PageContent content={definitions[12].html} />
                        </InfoModal>
                    </td>
                    <td>{filterBy(takeHomePayBefore)}</td>
                    <td>{filterBy(takeHomePaySimple)}</td>
                    <td>{filterBy(takeHomePaySmart)}</td>
                </tr>
            </tbody>
            <Table.Footer>
                <tr>
                    <td>
                        <InfoModal
                            triggerText='Total Pension Contribution'
                            heading={definitions[14].frontmatter.title}
                            variant='white'
                        >
                            <PageContent content={definitions[14].html} />
                        </InfoModal>
                    </td>
                    <td>{filterBy(totalPensionContributionBefore)}</td>
                    <td>{filterBy(totalPensionContributionSimple)}</td>
                    <td>{filterBy(totalPensionContributionSmart)}</td>
                </tr>
                <tr>
                    <td colSpan={1}>
                        <InfoModal
                            triggerText='Additional pension contribution with SMART'
                            heading={definitions[0].frontmatter.title}
                            variant='white'
                        >
                            <PageContent content={definitions[0].html} />
                        </InfoModal>
                    </td>
                    <td colSpan={3}>{filterBy(differenceUsingSmartVsBefore)}</td>
                </tr>
            </Table.Footer>
        </Table>
    );
};

Results.propTypes = {
    definitions: PropTypes.array.isRequired,
    salaryBefore: PropTypes.number.isRequired,
    salarySimple: PropTypes.number.isRequired,
    salarySmart: PropTypes.number.isRequired,
    personalAllowanceBefore: PropTypes.number.isRequired,
    personalAllowanceSimple: PropTypes.number.isRequired,
    personalAllowanceSmart: PropTypes.number.isRequired,
    incomeTaxBefore: PropTypes.number.isRequired,
    incomeTaxSimple: PropTypes.number.isRequired,
    incomeTaxSmart: PropTypes.number.isRequired,
    employeeNICBefore: PropTypes.number.isRequired,
    employeeNICSimple: PropTypes.number.isRequired,
    employeeNICSmart: PropTypes.number.isRequired,
    employerNICBefore: PropTypes.number.isRequired,
    employerNICSimple: PropTypes.number.isRequired,
    employerNICSmart: PropTypes.number.isRequired,
    takeHomePayBefore: PropTypes.number.isRequired,
    takeHomePaySimple: PropTypes.number.isRequired,
    takeHomePaySmart: PropTypes.number.isRequired,
    totalPensionContributionBefore: PropTypes.number.isRequired,
    totalPensionContributionSimple: PropTypes.number.isRequired,
    totalPensionContributionSmart: PropTypes.number.isRequired,
    differenceUsingSmartVsBefore: PropTypes.number.isRequired,
    taxableIncomeBefore: PropTypes.number.isRequired,
    taxableIncomeSimple: PropTypes.number.isRequired,
    taxableIncomeSmart: PropTypes.number.isRequired,
};

export default Results;