/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { withPrefix, Link } from 'gatsby';
import debounce from 'lodash.debounce';

// Components
import Section from '../../components/section';
import Form from '../../components/form';
import Select from '../../components/select';
import SummaryCard from '../../components/summary-card';
import InputField from '../../components/input-field';
import Loader from '../../components/loader';
import DownloadModal from '../../components/download-modal';
import ShareResults from '../../components/share-results';
import Content, { HTMLContent } from "../../components/content-renderer";

// Helpers
import withShareResults from '../../helpers/with-share-results';

// Partials
import Results from './results';

// Documents
import SampleReport from '../../assets/documents/example-salary-sacrifice-report.pdf';
import SalarySacrificeReport from '../../documents/salary-sacrifice-report';

const SalarySacrificeCalculator = ({
    definitions,
    pageInfo,
}) => {
    const { urlParams, getShareLink, selectedYearParam } = withShareResults();
    const PageContent = HTMLContent || Content;
    const headerImage = pageInfo.frontmatter.image.relativePath;
    
    const [state, setState] = useState({
        isLoading: false,
        grossIncome: urlParams?.get('grossIncome') || '',
        paidPer: urlParams?.get('paidPer') || 'year',
        employeeContribution: urlParams?.get('employeeContribution') || '',
        employerContribution: urlParams?.get('employerContribution') || '',
        percentageSacrificed: urlParams?.get('percentageSacrificed') || '',
        employeeContributionInPounds: urlParams?.get('employeeContributionInPounds') || false,
        employerContributionInPounds: urlParams?.get('employerContributionInPounds') || false,
        salarySmart: 0,
        personalAllowanceSmart: 0,
        taxableIncomeSmart: 0,
        employeeIncomeTaxSmart: 0,
        employeeNICSmart: 0,
        employerNICSmart: 0,
        employeePensionContributionSmart: 0,
        employerPensionContributionSmart: 0,
        takeHomePaySmart: 0,
        employerNISavings: 0,
        totalPensionContributionSmart: 0,
        differenceUsingSmartVsBefore: 0,
        getSalary: 0,
    });

	// React Redux hooks
	const dispatch = useDispatch();
    const figures = useSelector(state => state.yearlyFigures);
    const query = useSelector(state => state.salarySacrifice);

    const PDFRef = useRef(null);

    useEffect(() => {
        return () => {
            dispatch({ type: 'RESET_YEAR' });
            dispatch({ type: 'RESET_SALARY_SACRIFICE_SELECTIONS' });
        };
    }, []);

    // Applies url params on load
    useEffect(() => {
        if (selectedYearParam) {
            dispatch({ type: 'SET_YEAR', payload: selectedYearParam });
        }

        dispatch({ type: 'APPLY_SALARY_SACRIFICE_URL_PARAMS' });
    }, [selectedYearParam]);

    // Check if value is < 0 => rationalised
    const checkNegative = (value) => value < 0 ? 0 : value;

    // Debounce input fields to stop multiple calculations running at once
    const debounceIncome = useRef(debounce((type, nextValue) => dispatch({ type: type, payload: nextValue }), 1000)).current;

    const debounceInputChange = (stateName, dispatchName, payload, event) => {
        event.preventDefault();

        const updateState = {...state};

        updateState[stateName] = payload;
        updateState.isLoading = true;

        setState(updateState);

        debounceIncome(dispatchName, payload);
    };

    // Calculator variables
    const personalAllowance = figures.personalAllowance;
    const basicAllowance = figures.niHigherBand - figures.personalAllowance;
    const paReducerAllowance = figures.personalAllowanceReducerAllowance;
    const higherAllowance = figures.higherRateBand;

    const nilRate = 0 / 100;

    const basicRate = figures.basicRateTax;
    const higherRate = figures.higherRateTax;
    const topRate = figures.additionalRateTax;

    const primaryThreshold = figures.niThreshold;
    const secondaryThreshold = figures.niSecondaryThreshold;
    const upperEarningLimit = figures.niHigherBand;

    const niNilRate = 0 / 100;
    const niBasicRate = figures.niBasicRate;
    const niHigherRate = figures.niHigherRate;

    // EMPLOYEES SAVE UP TO 13.8% ON NATIONAL INSURANCE CONTRIBUTIONS
    const niRateAboveSecondaryThreshold = figures.niRateAboveSecondaryThreshold;

    // Calculate salary based on 'Paid per' period input
	const calcGrossIncome = () => {
        return (query.paidPer === 'year') ? query.grossIncome :
            (query.paidPer === 'month') ? query.grossIncome * 12 :
            (query.paidPer === 'fortnight') ? query.grossIncome * 26 :
            (query.paidPer === 'week') ? query.grossIncome * 52 :
            (query.paidPer === 'day') ? query.grossIncome * 260 :
            (query.paidPer === 'hour') ? query.grossIncome * 1950 :
            null;
    };

    // Current user input values
    const salaryBefore = query.grossIncome ? calcGrossIncome() : 0;

    let employeeContribution = query.employeeContributionInPounds ? (100 / (salaryBefore / query.employeeContribution)) / 100 : query.employeeContribution / 100;
    let employerContribution = query.employerContributionInPounds ? (100 / (salaryBefore / query.employerContribution)) / 100 : query.employerContribution / 100;

    const employeePensionContributionBefore = salaryBefore * employeeContribution;
    const employerPensionContributionBefore = salaryBefore * employerContribution;

    const employerSavingGivenUp = query.percentageSacrificed ? query.percentageSacrificed / 100 : 0;

    // Calculation algorithms
    const personalAllowanceBefore = Math.max(0, personalAllowance - Math.max(salaryBefore - paReducerAllowance - employeePensionContributionBefore, 0) / 2);
    const taxableIncomeBefore = calcGrossIncome() - personalAllowanceBefore - employeePensionContributionBefore;
    const employeeIncomeTaxBefore = Math.min(salaryBefore, personalAllowanceBefore) * nilRate + Math.max(0, Math.min(basicAllowance, salaryBefore - personalAllowanceBefore - employeePensionContributionBefore)) * basicRate + Math.max(0, Math.min(higherAllowance, salaryBefore - personalAllowanceBefore - employeePensionContributionBefore) - basicAllowance) * higherRate + Math.max(0, salaryBefore - personalAllowanceBefore - employeePensionContributionBefore - higherAllowance) * topRate;
    const employeeNICBefore = Math.min(salaryBefore, primaryThreshold) * niNilRate + Math.max(0, Math.min(upperEarningLimit, salaryBefore) - primaryThreshold) * niBasicRate + (Math.max(salaryBefore, upperEarningLimit) - upperEarningLimit)* niHigherRate;
    const employerNICBefore = Math.max(0, salaryBefore - secondaryThreshold) * niRateAboveSecondaryThreshold;
    const takeHomePayBefore = salaryBefore - employeePensionContributionBefore - employeeIncomeTaxBefore - employeeNICBefore;
    const totalPensionContributionBefore = employeePensionContributionBefore + employerPensionContributionBefore;

    const salarySimple = salaryBefore - employeePensionContributionBefore;
    const personalAllowanceSimple = Math.max(0, personalAllowance - Math.max(salarySimple - paReducerAllowance, 0) / 2);
    const taxableIncomeSimple = calcGrossIncome() - personalAllowanceSimple - employeePensionContributionBefore;
    const employeeIncomeTaxSimple = Math.min(salarySimple, personalAllowanceBefore) * nilRate + Math.max(0, Math.min(basicAllowance, salarySimple - personalAllowanceBefore)) * basicRate + Math.max(0, Math.min(higherAllowance, salarySimple - personalAllowanceBefore) - basicAllowance) * higherRate + Math.max(0, salarySimple - personalAllowanceBefore - higherAllowance) * topRate;
    const employeeNICSimple = Math.min(salarySimple, primaryThreshold) * niNilRate + Math.max(0, Math.min(upperEarningLimit, salarySimple) - primaryThreshold) * niBasicRate + (Math.max(salarySimple, upperEarningLimit) - upperEarningLimit) * niHigherRate;
    const employerNICSimple = Math.max(0, salarySimple - secondaryThreshold) * niRateAboveSecondaryThreshold;
    const employerPensionContributionSimple = salaryBefore - salarySimple + (employerNICBefore - employerNICSimple) * employerSavingGivenUp + employerPensionContributionBefore;
    const takeHomePaySimple = salarySimple - employeeIncomeTaxSimple-employeeNICSimple;
    const totalPensionContributionSimple = employerPensionContributionSimple;
    
    const calculateSmart = () => {
        const personalAllowanceSmart = Math.max(0, personalAllowance - Math.max(state.salarySmart - paReducerAllowance, 0) / 2);
        const taxableIncomeSmart = calcGrossIncome() - personalAllowanceSmart - employeePensionContributionBefore;
        const employeeIncomeTaxSmart = Math.min(state.salarySmart, personalAllowanceBefore) * nilRate + Math.max(0, Math.min(basicAllowance, state.salarySmart - personalAllowanceBefore)) * basicRate + Math.max(0, Math.min(higherAllowance, state.salarySmart - personalAllowanceBefore) - basicAllowance) * higherRate + Math.max(0, state.salarySmart - personalAllowanceBefore - higherAllowance) * topRate;
        const employeeNICSmart = Math.min(state.salarySmart, primaryThreshold) * niNilRate + Math.max(0, Math.min(upperEarningLimit, state.salarySmart) - primaryThreshold) * niBasicRate + (Math.max(state.salarySmart, upperEarningLimit) - upperEarningLimit) * niHigherRate;
        const employerNICSmart = Math.max(0, state.salarySmart - secondaryThreshold) * niRateAboveSecondaryThreshold;
        const employeePensionContributionSmart = salaryBefore - state.salarySmart;
        const employerNISavings = (employerNICBefore - employerNICSmart) * employerSavingGivenUp;
        const employerPensionContributionSmart = employeePensionContributionSmart + employerNISavings + employerPensionContributionBefore;
        const takeHomePaySmart = state.salarySmart - employeeIncomeTaxSmart - employeeNICSmart;
        const totalPensionContributionSmart = employerPensionContributionSmart;
        const differenceUsingSmartVsBefore = totalPensionContributionSmart - totalPensionContributionBefore;

        const GS = (takeHomePayBefore - takeHomePaySmart).toFixed(6);
        
        setState({...state, getSalary: GS});

        if (GS != 0) {
            var controlVariable = state.salarySmart + (GS / 2);
            setState({
                ...state,
                salarySmart: controlVariable,
                isLoading: true,
            });
        }

        if (GS == 0) {
            setState({
                ...state,
                personalAllowanceSmart: personalAllowanceSmart,
                taxableIncomeSmart: taxableIncomeSmart,
                employeeIncomeTaxSmart: employeeIncomeTaxSmart,
                employeeNICSmart: employeeNICSmart,
                employerNICSmart: employerNICSmart,
                employeePensionContributionSmart: employeePensionContributionSmart,
                employerPensionContributionSmart: employerPensionContributionSmart,
                takeHomePaySmart: takeHomePaySmart,
                employerNISavings: employerNISavings,
                totalPensionContributionSmart: totalPensionContributionSmart,
                differenceUsingSmartVsBefore: differenceUsingSmartVsBefore,
                isLoading: false,
            });
        }
    };

    useEffect(() => {
        calculateSmart();
    }, [
        query,
        state.salarySmart,
    ]);

    return (
        <>
            <Section label='Calculator'>
                <Form>
                    <Form.Fieldset>
                        <Form.Row>
                            <InputField
                                id='gross-income'
                                labelText='Gross income'
                                modalHeading={definitions[8].frontmatter.title}
                                modalContent={<PageContent content={definitions[8].html} />}
                                placeholder='0'
                                symbol='currency'
                                min={0}
                                onChange={(event) => debounceInputChange('grossIncome', 'SET_SALARY_SACRIFICE_GROSS_INCOME', event.target.value, event)}
                                required
                                value={state.grossIncome}
                            />

                            <Select
                                labelText="Paid per"
                                id="paid-select"
                                onChange={(event) => debounceInputChange('paidPer', 'SET_SALARY_SACRIFICE_PAID_PER', event.target.value, event)}
                                value={state.paidPer}
                            >
                                <Select.Option value="year">Year</Select.Option>
                                <Select.Option value="month">Month</Select.Option>
                                <Select.Option value="fortnight">Fortnight</Select.Option>
                                <Select.Option value="week">Week</Select.Option>
                                <Select.Option value="day">Day</Select.Option>
                                <Select.Option value="hour">Hour</Select.Option>
                            </Select>

                            <Select
                                labelText="Tax year"
                                id="tax-year-select"
                                onChange={(event) => {
                                    dispatch({
                                        type: 'SET_YEAR',
                                        payload: event.target.value,
                                    });
                                }}
                                value={figures.selectedYear}
                            >
                                <Select.Option value='2024'>2024/25</Select.Option>
                                <Select.Option value='2023'>2023/24</Select.Option>
                                <Select.Option value='2022'>2022/23</Select.Option>
                                <Select.Option value='2021'>2021/22</Select.Option>
                                <Select.Option value='2020'>2020/21</Select.Option>
                                <Select.Option value='2019'>2019/20</Select.Option>
                                <Select.Option value='2018'>2018/19</Select.Option>
                                <Select.Option value='2017'>2017/18</Select.Option>
                                <Select.Option value='2016'>2016/17</Select.Option>
                            </Select>
                        </Form.Row>
                        
                        <Form.Row>
                            <InputField
                                id='employee-contribution'
                                labelText='Employee pension contribution'
                                modalHeading={definitions[4].frontmatter.title}
                                modalContent={<PageContent content={definitions[4].html} />}
                                placeholder='0'
                                symbol={!query.employeeContributionInPounds ? 'percentage' : 'currency'}
                                min={0}
                                max={!query.employeeContributionInPounds ? 100 : undefined}
                                onChange={(event) => debounceInputChange('employeeContribution', 'SET_SALARY_SACRIFICE_EMPLOYEE_CONTRIBUTION', event.target.value, event)}
                                value={state.employeeContribution}
                                checkboxText='Fixed £ amount'
                                checkboxOnChange={(event) => debounceInputChange('employeeContributionInPounds', 'SET_TAKE_SALARY_SACRIFICE_EMPLOYEE_CONTRIBUTION_IN_POUNDS', !state.employeeContributionInPounds, event)}
                                checkboxValue={state.employeeContributionInPounds}
                            />

                            <InputField
                                id='employer-contribution'
                                labelText='Employer pension contribution'
                                modalHeading={definitions[7].frontmatter.title}
                                modalContent={<PageContent content={definitions[7].html} />}
                                placeholder='0'
                                symbol={!query.employerContributionInPounds ? 'percentage' : 'currency'}
                                min={0}
                                max={!query.employerContributionInPounds ? 100 : undefined}
                                onChange={(event) => debounceInputChange('employerContribution', 'SET_SALARY_SACRIFICE_EMPLOYER_CONTRIBUTION', event.target.value, event)}
                                value={state.employerContribution}
                                checkboxText='Fixed £ amount'
                                checkboxOnChange={(event) => debounceInputChange('employerContributionInPounds', 'SET_TAKE_SALARY_SACRIFICE_EMPLOYER_CONTRIBUTION_IN_POUNDS', !state.employerContributionInPounds, event)}
                                checkboxValue={state.employerContributionInPounds}
                            />

                            <InputField
                                id='employer-ni-saving-given-up'
                                labelText='Employer NI saving given up'
                                modalHeading={definitions[6].frontmatter.title}
                                modalContent={<PageContent content={definitions[6].html} />}
                                placeholder='0'
                                symbol='percentage'
                                min={0}
                                max={100}
                                onChange={(event) => debounceInputChange('percentageSacrificed', 'SET_SALARY_SACRIFICE_PERCENTAGE_SACRIFICED', event.target.value, event)}
                                value={state.percentageSacrificed}
                            />
                        </Form.Row>
                    </Form.Fieldset>
                </Form>
            </Section>
            
            <Section label='Calculation results' variants={['padded', 'secondary']}>
                <SummaryCard
                    id='salary-sacrifice'
                    heading='Your results'
                    select={[
						{name: 'Year', value: 'year'},
						{name: 'Month', value: 'month'},
						{name: 'Week', value: 'week'},
						{name: 'Day', value: 'day'},
					]}
					selectID='results-filter'
					selectOnChange={(event) => dispatch({
						type: 'SET_SALARY_SACRIFICE_FILTER_RESULTS',
						payload: event.target.value,
					})}
                >
                    <div style={{position: 'relative'}}>
                        {state.isLoading && (
                            <Loader text='Calculating...' />
                        )}
                        
                        <Results
                            definitions={definitions}
                            salaryBefore={salaryBefore}
                            salarySimple={salarySimple}
                            salarySmart={state.salarySmart}
                            personalAllowanceBefore={personalAllowanceBefore}
                            personalAllowanceSimple={personalAllowanceSimple}
                            personalAllowanceSmart={state.personalAllowanceSmart}
                            incomeTaxBefore={employeeIncomeTaxBefore}
                            incomeTaxSimple={employeeIncomeTaxSimple}
                            incomeTaxSmart={state.employeeIncomeTaxSmart}
                            employeeNICBefore={employeeNICBefore}
                            employeeNICSimple={employeeNICSimple}
                            employeeNICSmart={state.employeeNICSmart}
                            employerNICBefore={employerNICBefore}
                            employerNICSimple={employerNICSimple}
                            employerNICSmart={state.employerNICSmart}
                            takeHomePayBefore={takeHomePayBefore}
                            takeHomePaySimple={takeHomePaySimple}
                            takeHomePaySmart={state.takeHomePaySmart}
                            totalPensionContributionBefore={totalPensionContributionBefore}
                            totalPensionContributionSimple={totalPensionContributionSimple}
                            totalPensionContributionSmart={state.totalPensionContributionSmart}
                            differenceUsingSmartVsBefore={state.differenceUsingSmartVsBefore}
                            taxableIncomeBefore={checkNegative(taxableIncomeBefore)}
                            taxableIncomeSimple={checkNegative(taxableIncomeSimple)}
                            taxableIncomeSmart={checkNegative(state.taxableIncomeSmart)}
                        />
                    </div>

                    <div className='summary-card__controls'>
                        <ShareResults url={getShareLink(query, figures)} />

                        <DownloadModal
                            sample={SampleReport}
                            elementRef={PDFRef}
                            PDFName='salary-sacrifice-report'
                            PDFTitle={pageInfo.frontmatter.title}
                        />
                    </div>

                    <Link to='/personal-finance/salary' style={{display: 'inline-flex', margin: '25px auto 0'}}>Get a full breakdown of your salary</Link>
                </SummaryCard>
            </Section>

            <SalarySacrificeReport
                headerImage={`${withPrefix("/")}img/${headerImage}`}
                heading={pageInfo.frontmatter.title}
                description={pageInfo.frontmatter.description}
                definitions={definitions}
                salaryBefore={salaryBefore}
                salarySimple={salarySimple}
                salarySmart={state.salarySmart}
                personalAllowanceBefore={personalAllowanceBefore}
                personalAllowanceSimple={personalAllowanceSimple}
                personalAllowanceSmart={state.personalAllowanceSmart}
                incomeTaxBefore={employeeIncomeTaxBefore}
                incomeTaxSimple={employeeIncomeTaxSimple}
                incomeTaxSmart={state.employeeIncomeTaxSmart}
                employeeNICBefore={employeeNICBefore}
                employeeNICSimple={employeeNICSimple}
                employeeNICSmart={state.employeeNICSmart}
                employerNICBefore={employerNICBefore}
                employerNICSimple={employerNICSimple}
                employerNICSmart={state.employerNICSmart}
                takeHomePayBefore={takeHomePayBefore}
                takeHomePaySimple={takeHomePaySimple}
                takeHomePaySmart={state.takeHomePaySmart}
                totalPensionContributionBefore={totalPensionContributionBefore}
                totalPensionContributionSimple={totalPensionContributionSimple}
                employerNISavings={state.employerNISavings}
                employeePensionContributionSmart={state.employeePensionContributionSmart}
                employerPensionContributionBefore={employerPensionContributionBefore}
                totalPensionContributionSmart={state.totalPensionContributionSmart}
                differenceUsingSmartVsBefore={state.differenceUsingSmartVsBefore}
                taxableIncomeBefore={checkNegative(taxableIncomeBefore)}
                taxableIncomeSimple={checkNegative(taxableIncomeSimple)}
                taxableIncomeSmart={checkNegative(state.taxableIncomeSmart)}
                ref={PDFRef}
            />
        </>
    );
};

SalarySacrificeCalculator.propTypes = {
    definitions: PropTypes.array.isRequired,
    pageInfo: PropTypes.object.isRequired,
};

export default SalarySacrificeCalculator;